import * as React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import BlogPreview from "../components/blog/blogPreview";
import BlogSidebar from "../components/blog/blogSidebar";
import { H1 } from "../components/atoms/headings";

// styles

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: var(--top-margin);
  max-width: 1280px;
  min-height: 100vh;
  padding: 1rem;
  @media(min-width: 1280px) {
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const BlogContainer = styled.div`
  order: 2;
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    order: 1;
    flex: 800px;
  }
`;

const Blog = ({ data, pageContext }) => {
  const doc = data.allPrismicBlogPost.nodes;
  const lang = doc[0].lang;
  const type = `blog`;
  const url = `/blog`;

  const [tag, setTag] = React.useState("");


  // let tag = /[^/]*$/.exec(window.location.href)[0];

  React.useEffect(() => {
    setTag(/[^/]*$/.exec(window.location.href)[0]);
  })

  const alternateLanguages = doc[0].alternate_languages;
  const activeDoc = {
    lang,
    type,
    url,
    tag,
    alternateLanguages,
  };




  if (typeof window === `undefined`) {
    return(<></>);
}

  return (
    <Layout activeDocMeta={activeDoc}>
      <SEO
        title="CiteDrive Blog"
        lang={doc.lang}
        description="CiteDrive Blog"
        type="blog"
      />

      <Wrapper>
        <H1
          style={{ marginBottom: "3rem", fontFamily: "acumin-pro-wide, sans-serif" }}
        >
          Blog
        </H1>
        <Container>
          <BlogContainer>
            {doc.map((ele) => (
              <>
                <BlogPreview
                  title={ele.data.post_title.text}
                  date={ele.data.body1.map(ele => ele.primary)[0].date}
                  subTitle={ele.data.post_subtitle.text}
                  link={ele.url}
                  tags={ele.tags}
                  featuredImage={ele.data.featured_image.gatsbyImageData}
                  lang={ele.lang}
                />
              </>
            ))}
          </BlogContainer>
          <BlogSidebar lang={lang} />
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query myQuery($lang: String) {
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        id
        lang
        tags
        type
        uid
        url
        tags
        alternate_languages {
          id
          type
          lang
          uid
        }
        first_publication_date(formatString: "YYYY-DD-MM")
        data {
          body1 {
          ... on PrismicBlogPostDataBody1PostInformation {
            primary {
              date(formatString: "YYYY-DD-MM")
            }
          }
        }
          post_title {
            richText
            text
          }
          post_subtitle {
            richText
            text
          }
          featured_image {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
